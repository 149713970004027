const colors = {
  background: '#fdfdff',
  primary700: '#044b74',
  primary500: '#07689f',
  primary300: '#619ec1',
  primary100: '#8eb9d3',
  primary80: '#bbd5e4',
  primary20: '#e8f1f6',
  secondary10: '#eff9ff',
  secondary20: '#e7f5fe',
  secondary50: '#b9e3fd',
  secondary80: '#a2d5f2',
  secondary200: '#2eacfa',
  secondary300: '#009AFA',
  secondary500: '#007bc7',
  secondary600: '#005184',
  tertiary20: '#fff0e7',
  tertiary50: '#ffcfb0',
  tertiary80: '#ffbd91',
  tertiary100: '#FF9D5B',
  tertiary100alpha30: 'rgba(255,157,91,0.3)',
  tertiary200: '#ef9051',
  tertiary300: '#d0783f',
  green20: '#eff6e5',
  green80: '#a5d381',
  green200: '#72B01D',
  green500: '#497112',
  red: '#F35B5B',
  red20: '#fdf3f0',
  red80: '#f9bfb4',
  red400: '#F35B5B',
  red500: '#c34347',
  light: '#F1F2F6',
  white: '#ffffff',
  gray20: '#f3f3f7',
  gray50: '#d7d8e4',
  gray80: '#c4c6d7',
  gray100: '#b0b6ca',
  gray200: '#959FB8',
  gray300: '#848da5',
  gray400: '#696f84',
  gray500: '#4f5968',
  gray600: '#3b4551',
  gray700: '#313c46',
  gray800: '#1b1d21',
  darkestAlpha30: 'rgba(31,36,35,0.3)',
  gradient: 'linear-gradient(to right top, #07689f, #0074b5, #0081cc, #008de3, #009afa)',
  gunmetal800: '#272838',
  gunmetal100: '#aaaab0',
}

export default colors
