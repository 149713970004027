import React from 'react'
import { colors } from 'styles'

type AudioLogoProps = React.SVGProps<SVGSVGElement> & {
  color?: string
}

const AudioLogo: React.FC<AudioLogoProps> = ({ color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 138.901 138.901" {...props}>
    <g
      fillOpacity="1"
      fillRule="evenodd"
      stroke="none"
      strokeDasharray="none"
      strokeMiterlimit="4"
      transform="translate(-36.23 -77.712)"
    >
      <path
        fill={color ?? colors.secondary300}
        fillOpacity={color ? 0.6 : 1}
        strokeWidth="0"
        d="M107.654 186.9a30.17 30.17 0 00-30.236 30.237c0 5.04 0 10.078-7.863 12.597-7.864 2.52-23.592 2.52-39.319 2.52A30.17 30.17 0 000 262.49a30.17 30.17 0 0030.236 30.237c15.727 0 31.455 0 39.319 2.52 7.864 2.518 7.863 7.558 7.863 12.599a30.17 30.17 0 0030.236 30.236 30.17 30.17 0 0030.237-30.236v-90.71a30.17 30.17 0 00-30.237-30.236z"
        transform="matrix(.26458 0 0 .26458 36.23 77.712)"
      ></path>
      <path
        fill={color ?? colors.secondary300}
        fillOpacity={color ? 0.6 : 1}
        strokeWidth="0"
        d="M339.908 111.31c-16.75 0-30.236 13.484-30.236 30.235v241.89a30.17 30.17 0 0030.236 30.237 30.17 30.17 0 0030.237-30.236c0-15.12 0-30.237 7.865-37.795 7.864-7.56 23.59-7.559 39.316-7.559a30.17 30.17 0 0030.236-30.236c0-5.04.002-10.08 7.866-12.6 7.864-2.52 23.592-2.52 39.318-2.52 16.751 0 30.234-13.485 30.234-30.236 0-16.75-13.483-30.236-30.234-30.236-15.726 0-31.454 0-39.318-2.52-7.864-2.519-7.865-7.557-7.865-12.597 0-16.75-13.487-30.236-30.237-30.237-15.726 0-31.452 0-39.316-7.558-7.864-7.559-7.865-22.678-7.865-37.797 0-16.75-13.486-30.234-30.237-30.234z"
        transform="matrix(.26458 0 0 .26458 36.23 77.712)"
      ></path>
      <path
        fill={color ?? colors.tertiary100}
        strokeWidth="0"
        d="M262.49 1.703a30.17 30.17 0 00-30.236 30.236c0 26.458 0 52.915-7.863 66.143-7.864 13.228-23.59 13.229-39.319 13.229l.438.001v.01c-.146-.002-.291-.011-.438-.011-16.75 0-30.236 13.483-30.236 30.234v241.89a30.17 30.17 0 0030.236 30.237l-.435.004c15.728 0 31.455-.002 39.318 13.226 7.863 13.228 7.863 39.685 7.863 66.143h.436a30.17 30.17 0 0030.236 30.232 30.17 30.17 0 0030.237-30.236V31.939A30.17 30.17 0 00262.49 1.703z"
        transform="matrix(.26458 0 0 .26458 36.23 77.712)"
      ></path>
    </g>
  </svg>
)

export default AudioLogo
